import React from 'react';
import loadable from '@wix/yoshi-flow-editor/loadable';
import {
  useBi,
  useEnvironment,
  useExperiments,
  useTranslation,
} from '@wix/yoshi-flow-editor';
import stylesParams from '../../stylesParams';
import { useStyles } from '@wix/tpa-settings/react';
import { classes } from './Dialog.st.css';
import type {
  Basket,
  ServicesPreferencesModalProps,
} from '@wix/bookings-services-preferences-modal/types';
import { servicesPreferencesModalKeysMap as servicesPreferencesModalFormatterKeysMap } from './constants';
import { DialogViewModel } from '../../../../service-page-view-model/dialogViewModel/dialogViewModel';
import { useServicePageActions } from '../useServicePageActions';

export type DialogProps = {
  viewModel: DialogViewModel;
};

const ServicesPreferencesModal: React.FC<ServicesPreferencesModalProps> =
  loadable(() => import('@wix/bookings-services-preferences-modal'));

export const Dialog: React.FC<DialogProps> = ({ viewModel }) => {
  const { experiments } = useExperiments();
  const { closeDialog, onPreferencesModalServiceSelected, navigateToCalendar } =
    useServicePageActions();
  const { t } = useTranslation();
  const { isMobile, isRTL } = useEnvironment();
  const styles = useStyles();
  const biLogger = useBi();

  if (viewModel.isOpen) {
    return (
      <ServicesPreferencesModal
        className={classes.servicesPreferencesModal}
        {...viewModel.data!}
        widgetStyles={{
          primaryColor: styles.get(stylesParams.bodyButtonBackgroundColor)
            .value,
        }}
        t={t}
        onConfirm={(basket: Basket) => {
          closeDialog();
          navigateToCalendar({ basket });
        }}
        onClose={closeDialog}
        onServiceSelected={onPreferencesModalServiceSelected}
        isMobile={isMobile}
        isRTL={isRTL}
        biLogger={biLogger}
        formatterKeysMap={servicesPreferencesModalFormatterKeysMap}
        experiments={experiments}
        openedBy="service_page"
      />
    );
  }

  return null;
};
