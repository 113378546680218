import React from 'react';
import { ServicePageActions } from '../actions/actions';

export const ServicePageActionsContext =
  React.createContext<ServicePageActions>({
    navigateToCalendar: async () => {},
    changeTimezoneCallback: () => {},
    changeLocationCallback: () => {},
    openServicesPreferencesModal: async () => {},
    closeDialog: () => {},
    onPreferencesModalServiceSelected: async () => {},
    reportPageLoaded: () => {},
  });

export const ServicePageActionsConsumer = ServicePageActionsContext.Consumer;
export const ServicePageActionsProvider = ServicePageActionsContext.Provider;

export const useServicePageActions = () =>
  React.useContext(ServicePageActionsContext);
