import React from 'react';
import { MainComponents } from '../types';

export const RenderLocationContext = React.createContext<MainComponents>(
  MainComponents.BODY,
);

export const RenderLocationConsumer = RenderLocationContext.Consumer;
export const RenderLocationProvider = RenderLocationContext.Provider;

export const useRenderLocation = () => React.useContext(RenderLocationContext);
