import React from 'react';
import { useTranslation, useEnvironment, useExperiments } from '@wix/yoshi-flow-editor';
import {
  Button,
  HoverStyles,
  ButtonPriority as PRIORITY,
  ButtonSize as SIZE,
} from 'wix-ui-tpa/cssVars';
import { useSettings, useStyles } from '@wix/tpa-settings/react';
import { SpinnerWrapper } from '@wix/bookings-viewer-ui';
import settingsParams from '../../settingsParams';
import stylesParams from '../../stylesParams';
import { useRenderLocation } from '../useRenderLocation';
import { useServicePageActions } from '../useServicePageActions';
import { useBookButtonViewModel } from '../useBookButtonViewModel';
import { st, classes } from './BookButton.st.css';

interface ControllerProps {
  dataHook?: string;
  className?: string;
  renderedAt?: string;
}

type BookButtonProps = ControllerProps;

const BookButtonComponent: React.FC<BookButtonProps> = ({
  dataHook,
  className,
  renderedAt,
}) => {
  const { t } = useTranslation();
  const styles = useStyles();
  const location = useRenderLocation();
  const settings = useSettings();
  const { navigateToCalendar, openServicesPreferencesModal } =
    useServicePageActions();
  const { isMobile } = useEnvironment();
  const bookButtonViewModel = useBookButtonViewModel();
  const loading =
    !!bookButtonViewModel?.navigationInitiatedBy &&
    bookButtonViewModel?.navigationInitiatedBy === renderedAt;
  const spinnerDiameter = isMobile
    ? styles.get(stylesParams.mobileButtonTextSize)
    : styles.get(stylesParams.bodyButtonTextFont).size!;

  const buttonText = bookButtonViewModel?.isPendingApprovalFlow
    ? settings.get(settingsParams.pendingApprovalButtonText)
    : settings.get(settingsParams.bookButtonText);
  const { experiments } = useExperiments();
  const isButtonHoverContrastEnabled = experiments.enabled(
    'specs.bookings.buttonHoverContrast',
  );
  return (
    <Button
      className={st(classes.root, { location, isMobile }, className)}
      size={SIZE.medium}
      data-hook={dataHook || 'book-button'}
      priority={PRIORITY.primary}
      onClick={() => {
        bookButtonViewModel?.shouldOpenServicesPreferencesModal
          ? openServicesPreferencesModal()
          : navigateToCalendar({ initiatedBy: renderedAt });
      }}
      role="link"
      fullWidth={settings.get(settingsParams.buttonsFullWidth)}
      upgrade
      disabled={loading}
      hoverStyle={
        isButtonHoverContrastEnabled ? HoverStyles.underline : undefined
      }
    >
      {loading ? (
        <div className={classes.spinnerWrapper}>
          <SpinnerWrapper
            className={classes.spinner}
            diameter={spinnerDiameter}
            data-hook="book-button-spinner"
          />
        </div>
      ) : (
        buttonText
      )}
    </Button>
  );
};

export default BookButtonComponent;
