import type { IStyles } from '@wix/tpa-settings';
import { useStyles } from '@wix/tpa-settings/react';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { sectionSettingsCssProperties } from '../Settings/useSettingsSections';
import { uninitializedVisibilitySettingsTrueValue } from '../settingsParams';
import stylesParams from '../stylesParams';
import { ISection, SectionTypes } from '../types';
import { useWidgetSections } from '../Widget/useWidgetSections';

let stylesForAllBreakpoints: IStyles[] | undefined;

function useCachedStyles() {
  const styles = useStyles();

  return {
    getStylesForAllBreakpoints() {
      if (!stylesForAllBreakpoints) {
        stylesForAllBreakpoints = styles.getStylesForAllBreakpoints();
      }

      return stylesForAllBreakpoints;
    },
  };
}

export function useSectionVisibilityCheck() {
  const { isCssPerBreakpoint } = useEnvironment();
  const { getStylesForAllBreakpoints } = useCachedStyles();
  const sections = useWidgetSections();

  const sectionShouldBeVisibleHandler = (
    keyInSectionSettings: keyof ISection,
    sectionType: SectionTypes,
    styleParamCssProperty: keyof typeof stylesParams,
  ) => {
    const section = sections.find(
      (currSection) => currSection.type === sectionType,
    );

    const settingsValue = section![keyInSectionSettings];
    if (typeof settingsValue === 'boolean') {
      return settingsValue;
    }
    if (isCssPerBreakpoint) {
      return getStylesForAllBreakpoints().some(
        ({ booleans }) => booleans[styleParamCssProperty],
      );
    } else {
      // @ts-expect-error
      return settingsValue === uninitializedVisibilitySettingsTrueValue;
    }
  };

  return {
    shouldSectionBeVisible(sectionType: SectionTypes) {
      return sectionShouldBeVisibleHandler(
        'visible',
        sectionType,
        sectionSettingsCssProperties[sectionType].isVisible,
      );
    },
    shouldSectionBookButtonBeVisible(sectionType: SectionTypes) {
      return sectionShouldBeVisibleHandler(
        'bookButton',
        sectionType,
        sectionSettingsCssProperties[sectionType].isBookButtonVisible,
      );
    },
  };
}
