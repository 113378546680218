import React from 'react';
import { BookButtonViewModel } from '../../../service-page-view-model/book-button-view-model/bookButtonViewModel';

export const BookButtonViewModelContext = React.createContext<
  BookButtonViewModel | undefined
>({ isPendingApprovalFlow: false });

export const BookButtonViewModelConsumer = BookButtonViewModelContext.Consumer;
export const BookButtonViewModelProvider = BookButtonViewModelContext.Provider;

export const useBookButtonViewModel = () =>
  React.useContext(BookButtonViewModelContext);
