import React from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { ISection, SectionTypes } from '../../types';
import { DescriptionSectionViewModel } from '../../../../service-page-view-model/description-section-view-modal/descriptionSectionViewModel';
import Section from '../Section';
import SectionTitle from '../SectionTitle/SectionTitle';
import SectionText from '../SectionText/SectionText';
import settingsParams from '../../settingsParams';
import { useSettings } from '@wix/tpa-settings/react';
import { classes } from './Description.st.css';
import { useVisibilityCheck } from '../../hooks/useVisibilityCheck';
import { useSectionVisibilityCheck } from '../../hooks/useSectionVisibilityCheck';

type DescriptionProps = {
  viewModel?: DescriptionSectionViewModel;
  section: ISection;
};

export default ({ viewModel, section }: DescriptionProps) => {
  const { t } = useTranslation();
  const settings = useSettings();
  const { shouldSectionBookButtonBeVisible } = useSectionVisibilityCheck();
  const withBookButton =
    shouldSectionBookButtonBeVisible(SectionTypes.DESCRIPTION) &&
    viewModel?.isBookable;

  return withBookButton || viewModel?.description ? (
    <Section
      sectionType={SectionTypes.DESCRIPTION}
      withBookButton={withBookButton}
    >
      {viewModel?.description && (
        <div data-hook="description-wrapper">
          <SectionTitle
            title={settings.get(settingsParams.descriptionTitleText)}
            dataHook="description-title"
          />
          <SectionText
            text={viewModel.description}
            className={classes.description}
            dataHook="description"
          />
        </div>
      )}
    </Section>
  ) : null;
};
