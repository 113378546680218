import React from 'react';
import { SectionTypes } from '../../types';
import { SlotsPlaceholder } from '@wix/widget-plugins-ooi';
import { classes } from './Plugin.st.css';
import Section from '../Section';

const Plugin: React.FC<{}> = () => {
  return (
    <Section sectionType={SectionTypes.PLUGIN}>
      <div className={classes.root}>
        <SlotsPlaceholder slotId="slot1" />
      </div>
    </Section>
  );
};

export default Plugin;
