import { SectionTypes } from '../../types';

export default (t, sectionType: SectionTypes) => {
  switch (sectionType) {
    case SectionTypes.DESCRIPTION:
      return t('app.description.title');
    case SectionTypes.TITLE_TAGLINE:
      return t('app.settings.titles.service-title');
    case SectionTypes.CONTACT:
      return t(
        'app.settings.sections.contact.default.contact-details-title.label',
      );
    case SectionTypes.DETAILS:
      return t('app.settings.sections.items.details.label');
    case SectionTypes.POLICY:
      return t('app.settings.sections.policy.default.title');
    case SectionTypes.SCHEDULING:
      return t('app.scheduling.title.default');
    default:
      return '';
  }
};
