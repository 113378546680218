import * as React from 'react';
import ReactModal from 'react-modal';
import { classes, st } from './TpaModal.st.css';
import CloseIcon from '../../../../assets/icons/CloseIcon.svg';

interface TpaModalProps {
  isOpen: boolean;
  onRequestClose: Function;
  isMobile: boolean;
  isRTL: boolean;
}

interface TpaModalState {
  isRendered: boolean;
}

export default class TpaModal extends React.PureComponent<
  TpaModalProps,
  TpaModalState
> {
  private readonly containerRef;
  constructor(props) {
    super(props);
    this.containerRef = React.createRef<HTMLDivElement>();
    this.getParentNode = this.getParentNode.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.state = {
      isRendered: false,
    };
  }

  closeModal() {
    const { onRequestClose } = this.props;
    onRequestClose();
  }

  getParentNode() {
    return this.containerRef.current;
  }

  componentDidMount() {
    this.setState({
      isRendered: true,
    });
  }

  render() {
    const { isOpen, children, isMobile, isRTL } = this.props;
    const { isRendered } = this.state;
    return (
      <div
        ref={this.containerRef}
        data-hook="react-modal-container"
        className={st(classes.root, { mobile: isMobile, isRTL })}
      >
        {isRendered ? (
          <ReactModal
            isOpen={isOpen}
            className={st(classes.modal)}
            onRequestClose={this.closeModal}
            overlayClassName={st(classes.overlay)}
            parentSelector={this.getParentNode}
            ariaHideApp={false}
          >
            <div
              className={st(classes.closeButton)}
              onClick={this.closeModal}
              aria-label="close"
              data-hook="modal-close-button"
            >
              <CloseIcon />
            </div>
            {children}
          </ReactModal>
        ) : null}
      </div>
    );
  }
}
