import React from 'react';
import { useSizeListener } from './useSizeListener';

export const DimensionsContext = React.createContext<{
  width: number;
  height: number;
}>({ height: 0, width: 0 });
export const DimensionsConsumer = DimensionsContext.Consumer;
export const DimensionsProvider = ({
  initWidgetDimensions,
  widgetRef,
  children,
}: {
  initWidgetDimensions?: { width: number; height: number };
  widgetRef?: React.RefObject<HTMLElement>;
  children: React.ReactNode;
}) => {
  const widgetBoundings = useSizeListener(widgetRef);
  return (
    <DimensionsContext.Provider value={initWidgetDimensions || widgetBoundings}>
      {children}
    </DimensionsContext.Provider>
  );
};

export const useDimensions = () => React.useContext(DimensionsContext);
